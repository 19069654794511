import React, { useEffect, useState } from 'react'
import isUndefined from 'lodash/isUndefined'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Spinner } from 'reactstrap'
import { navigate } from 'gatsby'
import { useAuth0 } from '@auth0/auth0-react'

import Background from '../components/common/Background'
import EmptyLayout from '../components/layouts/EmptyLayout'
import { searchQueryParams } from '../lib/helpers'

export default function Index() {
  const { loginWithRedirect, isAuthenticated, isLoading, logout } = useAuth0()

  const [displayAccountExistsModal, setDisplayAccountExistsModal] = useState(searchQueryParams('account-exists') === 't')

  useEffect(() => {
    if (displayAccountExistsModal || isLoading) return

    isAuthenticated ? navigate('/cases') : loginWithRedirect({ screen_hint: searchQueryParams('screen_hint') })
  }, [displayAccountExistsModal, isLoading, isAuthenticated])

  useEffect(() => {
    if (!displayAccountExistsModal) return

    if (isUndefined(searchQueryParams('state'))) {
      setDisplayAccountExistsModal(true)
    } else {
      logout({ returnTo: `${window.location.origin}?account-exists=t` })
    }
  }, [displayAccountExistsModal])

  return (
    <EmptyLayout>
      <Background>
        <Modal centered isOpen={displayAccountExistsModal}>
          <ModalHeader>Error</ModalHeader>

          <ModalBody>
            This email address is already in use.
            <br />
            <br />
            Please log in with the same method (Google or email/password) you used to sign up.
          </ModalBody>

          <ModalFooter>
            <Button color="primary" onClick={loginWithRedirect}>
              Log In
            </Button>
          </ModalFooter>
        </Modal>

        <Spinner color="primary" style={{ width: '4rem', height: '4rem' }} />
      </Background>
    </EmptyLayout>
  )
}
